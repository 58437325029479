export const getLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage;
  const lang = localStorage.getItem("lang") || browserLang || "en-US";
  if (lang === "ja") {
    return "ja-JP";
  }
  if (lang !== "en-US" && lang !== "ja-JP") {
    return "en-US";
  }
  return lang;
};

export default {};
