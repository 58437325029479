/* global document */

import React from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import "./index.css";
import { LangContextProvider } from "context";
import App from "./App";
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import reportWebVitals from "./reportWebVitals";

const resources = {
  "en-US": {
    // eslint-disable-next-line global-require
    translation: require("./assets/lang/default.json"),
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LangContextProvider>
      <App />
    </LangContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
