import { useCallback, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "lodash";
import axios from "axios";
import Cookies from "js-cookie";
import { getSSORedirectUri, lang, getDownloadUrl } from "utils";

const STATE_KEY = "mytxone:key";
const CODE_VERIFIER_KEY = "mytxone:cv";
const REDIRECT_KEY = "_reuri";
let isLogin = false;

export const useLogin = () => {
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(isLogin);
  const initRef = useRef(false);

  // runs oauth callback to get oauth tokens and gets mytxone session
  const login = useCallback(
    async (data) => {
      try {
        await axios.post("/api/v1/login", data);
        sessionStorage.removeItem(STATE_KEY);
        sessionStorage.removeItem(CODE_VERIFIER_KEY);
        setLoading(true);

        const rk = Cookies.get(REDIRECT_KEY);
        if (rk) {
          const link = document.createElement("a");
          link.href = getDownloadUrl();
          link.setAttribute("download", "");
          link.target = "_self";
          link.click();
          link.remove();

          // Redirect to overview page
          setTimeout(() => {
            setLoading(false);
            window.location.replace("/overview");
          }, 2000);
        } else {
          setLoading(false);
          window.location.replace("/overview");
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(_.get(err, "response.data.message", "Login failed"), {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  if (!initRef.current) {
    const searchParams = new URLSearchParams(search);
    const authCode = searchParams.get("auth_code");
    const state = searchParams.get("state");

    if (!isLogin && authCode && state && state === sessionStorage.getItem(STATE_KEY)) {
      isLogin = true;
      setLoading(true);
      login({
        code: authCode,
        redirectUri: getSSORedirectUri(),
        codeVerifier: sessionStorage.getItem(CODE_VERIFIER_KEY),
        language: lang.getLanguage(),
      });
      initRef.current = true;
    }
  }

  return { loading };
};

export default {};
