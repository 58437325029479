export const isPartner = (roleList) =>
  !!roleList.find((role) => {
    if (
      role === "partner.edgeone" ||
      role === "partner.stellarone" ||
      role === "partner.elementone" ||
      role === "partner.all"
    ) {
      return true;
    }
    return false;
  });

export const isInternal = (roleList) =>
  !!roleList.find((role) => {
    if (role === "internal" || role === "admin") {
      return true;
    }
    return false;
  });

export const canAccessLicenseFeature = (roleList) => {
  for (let i = 0; i < roleList.length; i += 1) {
    const role = roleList[i];
    if (
      role !== "partner.edgeone" &&
      role !== "partner.stellarone" &&
      role !== "partner.elementone"
    ) {
      return true;
    }
  }
  return false;
};

export const isBasic = (roleList) =>
  !!roleList.find((role) => {
    if (role === "basic") {
      return true;
    }
    return false;
  });

export const canAccessCustomerSupportFeature = (roleList, hasLicense) =>
  isBasic(roleList) && hasLicense;
