export const versionMaxLength = 32;
export const productSerialNumberMaxLength = 128;
export const subjectMaxLength = 255;
export const descriptionMaxLength = 15000;
export const troubleshootingStepsMaxLength = 1000;

export const getSupportTicketStatus = (status) => {
  switch (status) {
    case "creating":
      return "Creating";
    case "new":
      return "New";
    case "in progress":
      return "In progress";
    default:
      return "Closed";
  }
};

export const getSupportTicketStatusColor = (status) => {
  switch (status) {
    case "creating":
      return "#DE0B15";
    case "new":
      return "#DE0B15";
    case "in progress":
      return "#7349FD";
    default:
      return "#CCCCCC";
  }
};

export const renderProductModel = (productModel) => {
  switch (productModel) {
    case "none":
      return "-";
    case "":
      return "-";
    default:
      return productModel;
  }
};

export const isEdgeDevice = (value) =>
  ["EdgeIPS LE", "EdgeIPS Std", "EdgeIPS Pro", "EdgeFire Std"].indexOf(value) > -1;

export const showProductSerialNumber = (productSKU) => {
  switch (productSKU) {
    case "StellarOne":
      return false;
    case "StellarProtect":
      return false;
    default:
      return true;
  }
};
