import numeral from "numeral";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (d) => {
  if (!d) {
    return "";
  }
  const date = new Date(d);
  return `${date.getUTCFullYear()}-${numeral(date.getUTCMonth() + 1).format(
    "00"
  )}-${numeral(date.getUTCDate()).format("00")}`;
};

export const formatMediaMaterialDate = (d) => {
  if (!d) {
    return "";
  }
  const date = new Date(d);
  return `${monthNames[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
};
