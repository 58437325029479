import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useDropzoneErrMsg = (fileSize, maxFileCount) => {
  const { t } = useTranslation();
  return useMemo(
    () => (errCode) => {
      let errMsg = "";
      switch (errCode) {
        case "file-too-large":
          errMsg = t("common.dropzoneErrorMsg.fileTooLarge", { fileSize });
          break;
        case "file-invalid-type":
          errMsg = t("common.dropzoneErrorMsg.fileInvalidType");
          break;
        case "exceed-max-count":
          errMsg = t("common.dropzoneErrorMsg.exceedMaxCount", { maxFileCount });
          break;
        default:
          errMsg = t("common.errorMsg.unknownError");
          break;
      }
      if (!errCode) {
        // eslint-disable-next-line no-param-reassign
        errCode = 5000;
      }
      return errMsg;
    },
    [t, fileSize, maxFileCount]
  );
};

export default {};
