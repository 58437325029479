import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";

import { LandingPage } from "./pages/Landing/Landing";
import { ErrorBoundary } from "./components/ErrorBoundary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/idp_callback",
    lazy: async () => {
      const { IdPCallbackPage } = await import("./pages/IdPCallback");
      return { Component: IdPCallbackPage };
    },
  },
  {
    path: "/",
    lazy: async () => {
      const { AdminLayout } = await import("./containers/AdminLayout");
      return { Component: AdminLayout };
    },
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/overview",
        lazy: async () => {
          const { OverviewPage } = await import("./pages/Overview");
          return { Component: OverviewPage };
        },
      },
      {
        path: "/resources",
        lazy: async () => {
          const { MediaMaterial } = await import("./components/MediaMaterial");
          return { Component: MediaMaterial };
        },
      },
      {
        path: "/threat_encyclopedia",
        lazy: async () => {
          const { ThreatEncyclopediaPage } = await import("./pages/ThreatEncyclopedia");
          return { Component: ThreatEncyclopediaPage };
        },
      },
      {
        path: "/product_licensing",
        lazy: async () => {
          const { LicenseListPage } = await import("./pages/LicenseList");
          return { Component: LicenseListPage };
        },
      },
      {
        path: "/file/:compType",
        lazy: async () => {
          const { ComponentListPage } = await import("./pages/ComponentList");
          return { Component: ComponentListPage };
        },
      },
      {
        path: "/customer_support",
        lazy: async () => {
          const { CustomerSupportPage } = await import("./pages/CustomerSupport");
          return { Component: CustomerSupportPage };
        },
      },
      {
        path: "/partner_portal",
        lazy: async () => {
          const { PartnerPortalPage } = await import("./pages/PartnerPortal");
          return { Component: PartnerPortalPage };
        },
      },
    ],
  },
  {
    path: "/license_expired",
    lazy: async () => {
      const { LicenseExpired } = await import("./components/Error/LicenseExpired");
      return { Component: LicenseExpired };
    },
  },
  {
    path: "*",
    lazy: async () => {
      const { PageNotFound } = await import("./components/Error/PageNotFound");
      return { Component: PageNotFound };
    },
  },
]);

const primaryColor = "#1b0b38";
const secondaryColor = "#D71920";
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: "#D71920",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "28px",
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
            opacity: 0.78,
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: secondaryColor,
            opacity: 0.78,
          },
        },
        startIcon: {
          marginRight: 2,
        },
      },
    },
    MuiLink: {
      root: {
        color: "#241540 !important",
      },
    },
  },
});

const App = () => {
  useEffect(() => {
    const matomoContainerID = process.env.REACT_APP_MAOTMO_CONTAINER_ID;
    if (matomoContainerID) {
      var _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
      (function () {
        var d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = `https://ma.cs.txone.com/js/container_${matomoContainerID}.js`;
        s.parentNode.insertBefore(g, s);
      })();
    }
  }, []);

  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
