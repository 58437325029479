import React from "react";
import { IconButton } from "@mui/material";

import { ReactComponent as LinkedinImg } from "assets/images/icon_c_linkedin.svg";

export function LinkedinIcon() {
  return (
    <IconButton
      href="https://www.linkedin.com/company/txone-networks/posts/?feedView=all"
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkedinImg width={30} height={30} />
    </IconButton>
  );
}
export default LinkedinIcon;
