import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const useLoginStatusChecking = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasLoggedIn = Cookies.get("hasLoggedIn") === "1";
    if (hasLoggedIn) {
      navigate("/overview");
    }
  }, [navigate]);
};

export * from "./useFetch";
export * from "./useErrMsg";
export * from "./useSSO";
export * from "./useDropzoneErrMsg";
