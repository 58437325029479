import React from "react";
import { Link } from "react-router-dom";
import { useTheme, Box, Typography, Link as MaterialLink } from "@mui/material";
import { ReactComponent as WrongIcon } from "assets/images/icon_wrong.svg";
import { useTranslation, Trans } from "react-i18next";

export function ErrorBoundary() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#ECECF0",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <WrongIcon width={397} height={220} alt="server error" />
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#241540",
          font: "normal normal bold 50px/57px Ubuntu",
          marginTop: theme.spacing(5),
        }}
      >
        {t("pages.error.primaryMsg")}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: "#241540",
          font: "normal normal normal 24px/34px Ubuntu",
          marginTop: theme.spacing(3),
        }}
      >
        <Trans i18nKey="pages.error.returnToIndexPage">
          Return to
          <MaterialLink component={Link} href="/" color="inherit">
            index page
          </MaterialLink>
        </Trans>
      </Typography>
    </Box>
  );
}
export default ErrorBoundary;
