import React, { createContext, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

// import { lang as langUtils } from "utils";
import { usePureAxios } from "hooks";

const langCtxDefaultValue = {
  onChangeLang: async () => {},
};
export const LangContext = createContext(langCtxDefaultValue);

export function LangContextProvider({ children }) {
  const { isLoading, dataFetcher } = usePureAxios();
  const { enqueueSnackbar } = useSnackbar();
  const loadLangResource = useCallback(
    async (lang) => {
      if (!i18n.hasResourceBundle(lang, "translation")) {
        const resp = await dataFetcher(`/assets/lang/${lang}.json`);
        i18n.addResourceBundle(lang, "translation", resp.data);
      }
    },
    [dataFetcher]
  );
  const updateUserDefaultLang = useCallback(
    async (lang) => {
      await dataFetcher("/api/v1/user/lang", "put", {
        language: lang,
      });
    },
    [dataFetcher]
  );
  const ctxData = useMemo(
    () => ({
      onChangeLang: async (lang) => {
        const currentLang = localStorage.getItem("lang");
        if (lang === currentLang) {
          return;
        }

        try {
          await updateUserDefaultLang(lang);
          localStorage.setItem("lang", lang);

          await loadLangResource(lang);

          i18n.changeLanguage(lang, async (err, t) => {
            if (err) {
              enqueueSnackbar("error", t("changeLangFailure"));
            }
            return Promise.resolve();
          });
        } catch (err) {
          // ignore
        }
      },
    }),
    [enqueueSnackbar, loadLangResource, updateUserDefaultLang]
  );

  useEffect(() => {
    // (async () => {
    //   const lang = langUtils.getLanguage();
    //   if (lang !== "en-US") {
    //     await loadLangResource(lang);
    //     i18n.changeLanguage(lang, async (err, t) => {
    //       if (err) {
    //         enqueueSnackbar("error", t("changeLangFailure"));
    //       }
    //       return Promise.resolve();
    //     });
    //   }
    // })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return <LangContext.Provider value={ctxData}>{children}</LangContext.Provider>;
}
LangContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
