import Joi from "joi";
import _ from "lodash";

import { isEdgeSN } from "utils/license";
import {
  versionMaxLength,
  productSerialNumberMaxLength,
  subjectMaxLength,
  descriptionMaxLength,
  troubleshootingStepsMaxLength,
} from "utils/supportTicket";

export const licenseKey = Joi.string()
  .required()
  // eslint-disable-next-line prefer-regex-literals
  .pattern(new RegExp("^(\\w{4}-\\w{4}-\\w{4}-\\w{4})|(TE-\\w{4}(-\\w{5}){5})$"))
  .label("License Key");

export const validateLicenseFile = (formData, licenseTypeId) =>
  Joi.object({
    licenseKey,
    productSerialNumber: Joi.string()
      .required()
      .custom((value, helper) => {
        if (licenseTypeId === 1) {
          // eslint-disable-next-line prefer-regex-literals
          const regexp = new RegExp("^((\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})|(\\w{7}))$", "i");
          if (!regexp.test(value)) {
            return helper.message("Invalid format of Product Serial Number");
          }
        } else {
          // eslint-disable-next-line prefer-regex-literals
          const regexp = new RegExp(
            "^((\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})|(\\w{7})|((tx|tm|sn)[g|f|p|m|l][0-9]{11}))$",
            "i"
          );
          if (!regexp.test(value)) {
            return helper.message("Invalid format of Product Serial Number");
          }
        }
        return value;
      })
      .label("Product Serial Number"),
    macAddress: Joi.custom((value, helper) => {
      if (isEdgeSN(formData.productSerialNumber)) {
        if (!_.get(formData, "macAddress")) {
          return helper.message("MAC Address is required");
        }

        // eslint-disable-next-line prefer-regex-literals, prettier/prettier
        const macAddressRegex = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
        if (!macAddressRegex.test(value)) {
          return helper.message("Invalid MAC Address");
        }
      }
      return value;
    }).label("MAC Address"),
  }).validate(formData);

export const validateSupportTicket = (formData) =>
  Joi.object({
    productLine: Joi.string().max(64).required().label("Product Line"),
    productSKU: Joi.string().max(64).required().label("Product SKU"),
    productModel: Joi.string().allow(null, "").max(64).label("Product Model"),
    version: Joi.string().max(versionMaxLength).required().label("Software Firmware Version"),
    productSerialNumber: Joi.string()
      .allow(null, "")
      .max(productSerialNumberMaxLength)
      .label("Product Serial Number"),
    issueCategory: Joi.string().max(128).required().label("Issue Category"),
    issueSubcategory: Joi.string().max(128).required().label("Issue Sub-Category"),
    subject: Joi.string().max(subjectMaxLength).required().label("Subject"),
    description: Joi.string().max(descriptionMaxLength).required().label("Description"),
    troubleshootingSteps: Joi.string()
      .allow(null, "")
      .max(troubleshootingStepsMaxLength)
      .label("Troubleshooting Steps"),
    attachmentKeys: Joi.array().max(5).label("Attachment Keys "),
  }).validate(formData);
